footer{
    padding: 75px 0 90px 0;
    background: #000000;
}
.download-application-item{
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem 0;
    display: block;
    transition: .2s ease-in-out;
}
.download-application-item:hover{
    border: 0.5px solid transparent;
    transition: .2s ease-out;
    text-decoration: none;
    background-color: #7b1cc38c;
}
.text-download-application-item{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: center;
}
.ul-footer{
    list-style: none;
    padding: 0;
}
.li-footer{
    margin-bottom: 10px;
}
.link-footer{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-size: 15px;
    color: #FFFFFF;
    transition: .4s ease-in-out;
}
.link-footer:hover{
    color: #c501ff;
    transition: .4s ease-in-out;
    text-decoration: none;
}
.icon-application-footer{
    width: 20px;
    height: 25px;
}