.content-view-dashboard {
    background: #FFFFFF;
    padding: 1.5rem 1.5rem 5rem;
    border-radius: 10px;
}
.banner-shop-dashboard{
    width: 100%;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 250px;
    border-radius: 10px;
}
.consigne-title-steps.parametres{
    color: #000000;
}
.input-result-parametres-dashboard{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #5A5A5A;
    position: absolute;
    top: 35px;
    right: 0;
}
/* .select-result-parametres-dashboard{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #5A5A5A;
    position: absolute;
    top: 7px;
    right: 40px;
} */
.input-steps-inscription-vendeur.dashboard-parametre-input{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10rem;
}
.select-steps-inscription-vendeur.dashboard-parametre-input{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10rem;
}

.container-banner-shop {
    position: relative;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    /* height: auto; */
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.container-banner-shop:hover{
    background-color: #c501ff;
    border-radius: 12px;
}
.container-banner-shop:hover .image {
    opacity: 0.7;
}

.container-banner-shop:hover .middle {
    opacity: 1;
}

.logo-shop-dashboard{
    width: 100%;
    border-radius: 12px;
    height: 200px;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.legende-banner-logo{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
    margin-top: 5px;
}