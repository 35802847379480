.card-filter{
    background-color: #ffffff;
    padding: 15px 25px 200px;
    margin-bottom: 15px;
    min-height: 800px;
}
.titre-filter-component{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.35);
    margin-bottom: 20px;
}
.nom-categorie-filter{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #111111;
    margin-bottom: 40px;
}
.titre-filter-type{
    margin-bottom: 15px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.35);
}

.custom-control-label-filter{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.7);
}


.custom-checkbox .custom-control-label-filter::before {
    border-radius: 0rem;
    border: 1px solid #000000;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label-filter::after {
    background-color: #c501ff;
}
.content-type-filter{
    margin-bottom: 45px;
}
.custom-checkbox-filter{
    margin-bottom: 0;
}

section.range-slider {
    position: relative;
    width: 100%;
    height: 35px;
    text-align: center;
}

section.range-slider input {
    overflow: hidden;
    background: transparent;
    color: #000;
    border-radius: 55px !important;
    text-align: center;
}

section.range-slider input::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
}

section.range-slider input::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -moz-appearance: none;
    width: 9px;
}

section.range-slider input::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: rgba(0, 0, 0, 1);
    border: 0;
}
section.range-slider input:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
}
  section.range-slider input[type=range]::-moz-focus-outer {
  border: 0;
}

.has-search{
    position: relative;
    margin-bottom: 25px;
}
.has-search .form-control {
    padding-right: 2.375rem;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.35);
    border-radius: 55px;
}

.has-search .icon-search-filter {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    right: 15px;
    top: -2px;
    pointer-events: none;
    color: #aaa;
}

.rating-custom-filter{
    color: #c501ff !important;
    display: inline-flex !important;
    font-size: 12px !important;
    margin: 0!important;
}
.rating-custom-filter>li{
    margin: 3px !important;
}
.rating-custom-filter-text{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.7);
}

.radio.active-rate {
    border: 1px solid #c501ff;
    border-radius: 10px;
}