.item-stat-dashboad-view{
    background: #FFFFFF;
    padding: 2rem 2rem .9rem;
    width: 100%;
    position: relative;
    min-height: 155px;
    display: flex;
    flex-direction: column;
}
.icon-item-stat-dashboad-view{
    width: 40px;
    position: absolute;
    top: 25px;
}
.content-item-stat-dashboad-view{
    text-align: right;
}
.titre-item-stat-dashboad-view{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #383639;
}
.chiffre-item-stat-dashboad-view{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #383639;
    margin-top: 15px;
    display: block;
}
.chiffre-item-stat-dashboad-view>.currency{
    font-weight: 400;
    font-size: 18px;
}

.footer-item-stat-dashboad-view{
    border-top: 1px solid #EDE9E9;
    padding-top: .5rem;
    margin-top: auto;
}
.icon-footer-item-stat-dashboad-view{
    width: 16px;
    margin-right: 12px;
}
.text-footer-item-stat-dashboad-view{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #383639;
    display: flex;
    align-items: center;
}
.text-footer-item-stat-dashboad-view.link {
    transition: .2s ease-in-out;
}
.text-footer-item-stat-dashboad-view.link:hover {
    color: #c501ff;
    transition: .2s ease-in-out;
    text-decoration: none;
}
.card-dashboard{
    background: #FFFFFF;
    padding: 2rem;
}
.title-filter-dashboard{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #c501ff;
}
.select-filter-dashboard{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 100px;
    border: 1px solid transparent;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #252733;
    height: 45px;
    padding: .5rem 3rem .5rem 1rem;
    appearance: none;
    background: url(../../../img/icons/Shape.png) 92% / 13px no-repeat #fff;
}
.select-filter-dashboard:focus{
    border: 1px solid #7b1cc333;
}