.input-change-password{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.65);
    background: #FFFFFF;
    border: 0.5px solid #7b1cc367;
    box-sizing: border-box;
    border-radius: 35px;
    padding: 1.5rem 2rem;
}
.input-change-password:focus{
    border: 1px solid #c501ff;
}

.btn-change-password{
    background: #c501ff;
    border-radius: 35px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
    padding: .7rem 2rem;
    text-transform: uppercase;
    margin-bottom: 10px;
    transition: .2s ease-in-out;
    border: 1px solid #c501ff;
}
.btn-change-password:hover{
    background-color: #ffffff;
    color: #c501ff;
    transition: .2s ease-in-out;
    border: 1px solid #7b1cc323;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
}