
.card-boutique-item{
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}
.col-half-offset{
    margin-left: 2%
}
.link-visit-boutique{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    display: flex;
    font-size: 12px;
    line-height: 14px;
    justify-content: center;
    letter-spacing: 0.1em;
    padding: 13px;
    text-transform: uppercase;
    color: #000000;
    border: 1px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    transition: .3s ease-in-out;
}
.link-visit-boutique:hover{
    transition: .3s ease-in-out;
    text-decoration: none;
    background-color: #c501ff;
    color: #fff;
}
.image-card-boutique{
    width: 100%;
    height: 180px;
}
.marque-card-boutique{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 35px;
    text-transform: lowercase;
}
.marque-card-boutique::first-letter{
    text-transform: capitalize;
}
.content-card-boutique-item{
    padding: 1rem 2rem;
}