.btn-user-info-form-reverse{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.7);
    background: #ffffff;
    padding: 15px;
    border-radius: 25px;
    width: 100%;
    transition: .2s ease-in-out;
    margin-bottom: 15px;
    border: 1px solid #7b1cc3a8;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
}
.btn-user-info-form-reverse:hover{
    background-color: #c501ff;
    transition: .2s ease-in-out;
    color: #ffffff;
}