.cart-content.checkout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 1.5rem 0;
}
.cart-content.checkout>header{
    text-align: center;
}
.cart-content.checkout>header>.h-theme{
    text-transform: lowercase !important;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000 !important;
}

.cart-content.checkout>header>h4{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000;
}

.cart-content.checkout>header>.h-theme::first-letter{
    text-transform: capitalize;
}
.image-cart-empty{
    width: 200px;
    margin: 0 auto 75px;
}
.icon-confirmation-modal{
    width: 80px;
    height: 80px;
}
.title-confirmation-modal{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #282828;
    text-align: center;
}

.text-confirmation-modal {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #282828;
}