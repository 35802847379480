.content-details-product{
    background-color: #ffffff;
    /* padding: 4rem; */
}
.details-content-details-product, .details-content-details-product p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}
.details-content-details-product::first-letter{
    text-transform: capitalize;
}
.name-shop-content-details-product{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #5C5C5C;
}
.icon-name-shop-content-details-product{
    width: 20px;
    margin-right: 10px;
    margin-top: -8px;
}
.link-name-shop-content-details-product{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #5C5C5C;
    transition: .2s ease-in-out;
    display: block;
    cursor: pointer;
}
.link-name-shop-content-details-product:hover{
    text-decoration: none;
    color: #c501ff;
    transition: .2s ease-in-out;
}
.name-product-content-details-product{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 10px;
}
.rating-details-product{
    text-align: left !important;
    font-size: 20px;
    margin-bottom: 40px;
}
.rating-details-product a{
    margin-right: 10px;
}
.price-content-details-product{
	font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #c501ff;
}
.btn-theme-add-to-cart{
    background: #c501ff;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    padding: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border: 1px solid transparent;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: .2s ease-in-out;
}
.btn-theme-add-to-cart:hover{
    /* border: 1px solid #c501ff; */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    transition: .2s ease-in-out;
    background-color: #7b1cc3b2;
}
.icon-btn-theme-add-to-cart{
    width: 20px;
    position: absolute;
    left: 3rem;
}
.name-info-vendeur-content-details-product{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #5C5C5C;
}
.see-shop-info-vendeur-content-details-product{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: lowercase;
    color: #737070;
    transition: .2s ease-in-out;
}
.see-shop-info-vendeur-content-details-product:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    color: #c501ff;
}
.icon-see-shop-info-vendeur-content-details-product{
    color: #c501ff;
    width: 20px;
    height: 16px;
}
.audio-lecteur-content:focus{
    outline: none;
}
.audio-lecteur-content{
    height: 40px;
    width: 100%;
}