.rating-comment-product{
    color: #c501ff !important;
    width: 100%;
    font-size: 10px !important;
    margin: 10px 0 !important;
}
.rating-comment-product>li{
    margin: 3px !important;
}
.content-titre-product-commentaire-item{
    height: 35px;
}