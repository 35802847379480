.component-nav-vertical-dashboard {
  position: fixed;
  left: 0;
  max-width: 16.666667%;
  width: 100%;
  overflow: auto;
  min-height: 100%;
  /* height: 500rem; */
  padding-bottom: 50px;
  padding-top: 30px;
  background: #c501ff;
}

.component-nav-vertical-dashboard>.rs-sidenav-default {
  background-color: transparent;
  min-height: 50rem;
  margin-bottom: 1rem;
}

.dropdown-sidenav-dashboard>.rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content {
  color: #fff !important;
}

.dropdown-sidenav-dashboard>.rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.36) !important;
}

.dropdown-sidenav-dashboard {
  margin-bottom: 10px;
}

.dropdown-sidenav-dashboard>a {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #ffffff;
  transition: 0.2s ease-in-out;
}

.dropdown-sidenav-dashboard>a:hover {
  text-decoration: none;
  transition: 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.36) !important;
  color: #ffffff;
}

.dropdown-sidenav-dashboard>a:focus {
  text-decoration: none;
  transition: 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.36) !important;
  color: #ffffff;
}

.link-item-sidenav-dashboard {
  margin-bottom: 10px;
}

.link-item-sidenav-dashboard>.rs-nav-item-content {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #ffffff;
  transition: 0.2s ease-in-out;
}

.link-item-sidenav-dashboard>.rs-nav-item-content:hover,
.link-item-sidenav-dashboard>.rs-nav-item-content:focus,
.actived .link-item-sidenav-dashboard>.rs-nav-item-content {
  text-decoration: none;
  transition: 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.36) !important;
}

.icon-item-sidenav-dashboard {
  position: absolute;
  left: 20px;
  top: 13px;
}

.badge-nav-vertical-dashboard {
  display: block;
}

.badge-nav-vertical-dashboard>.rs-badge-content {
  background: #c166f9;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  right: 0;
  top: 10px;
}