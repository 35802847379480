.info-boutique-card{
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.03);
    width: 100%;
}
.evaluation-boutique-card{
    background: #c501ff;
    width: 100%;
}
.head-boutique-card{
    padding: .8rem 2rem;
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
}
.body-boutique-card{
    padding: .8rem 2rem;
}
.h-info-boutique-card{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.75);
}
.btn-follow-info-boutique-card-web{
    border: 0.5px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #111111;
    display: flex;
    width: 100%;
    padding: 7px;
    justify-content: center;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.btn-follow-info-boutique-card-web:hover{
    transition: .2s ease-in-out;
    background-color: #c501ff;
    color: #FFFFFF;
}
.btn-follow-info-boutique-card-web-light{
    border: 0.5px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
    display: flex;
    width: 100%;
    padding: 7px;
    justify-content: center;
    background-color: #c501ff;
    transition: .2s ease-in-out;
}
.btn-follow-info-boutique-card-web-light:hover{
    transition: .2s ease-in-out;
    background-color: #ffffff;
    color: #c501ff;
}
.btn-follow-info-boutique-card-mobile{
    border: 0.5px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #c501ff;
    padding: 7px;
    justify-content: center;
    background-color: transparent;
    transition: .2s ease-in-out;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
}
.btn-follow-info-boutique-card-mobile:hover{
    transition: .2s ease-in-out;
    background-color: #c501ff;
    color: #FFFFFF;
}
.btn-follow-info-boutique-card-mobile-light{
    border: 0.5px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 7px;
    justify-content: center;
    background-color: #c501ff;
    transition: .2s ease-in-out;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
}
.btn-follow-info-boutique-card-mobile-light:hover{
    transition: .2s ease-in-out;
    background-color: #ffffff;
    color: #c501ff;
}
.icon-btn-follow-info-boutique-card-mobile{
    width: 16px;
    height: 16px;
}
.titre-info-boutique-card{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.75);
}
.content-info-boutique-card{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.75);
}
.h-evaluation-boutique-card{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}
.btn-follow-evaluation-boutique-card-web{
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 15px;
    transition: .2s ease-in-out;
}
.btn-follow-evaluation-boutique-card-web:hover{
    text-decoration: none;
    transition: .2s ease-in-out;
    background-color: #ffffff;
    color: #c501ff;
}
.icon-btn-follow-evaluation-boutique-card-web{
    width: 16px;
    height: 16px;
    margin-left: auto;
}
.btn-follow-evaluation-boutique-card-mobile{
    color: #ffffffb4;
    transition: .2s ease-in-out;
    position: absolute;
    right: 0px;
    top: -4px;
}
.btn-follow-evaluation-boutique-card-mobile:hover{
    color: #ffffff;
    text-decoration: none;
    transition: .2s ease-in-out;
}
.body-evaluation-boutique{
    background-color: #c501ff;
    padding: .8rem 2rem;
}
.titre-evaluation-boutique-card{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.content-evaluation-boutique-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #FFFFFF;
}
.banner-shop-details{
    width: 100%;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 250px;
    border-radius: 10px;
}
.empty-alert-icon {
    width: 50px;
    height: 50px;
    color: #c501ff;
}

.marketplace-alert-info {
    background: #7b1cc323 !important;
    border-color: #7b1cc36c !important;
    color: #c501ff !important;
    text-align: justify !important;
}

.empty-badge-alert {
    width: 100%;
    font-size: 20px;
    border-radius: 10px;
}

.empty-badge-alert p {
    color: #c501ff;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}
.empty-textual-col {
    text-align: left;
    display: flex;
    align-items: center;
}