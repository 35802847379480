.shop-icon-header-dashboard{
    width: 20px;
    margin-right: 20px;
}
.shop-name-header-dashboard{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.57);
}
.logout{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #c501ff;
    padding: 0;
    border: none;
    background: transparent;
}
.rate-number-header-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #444444;
}
.link-rate-header-dashboard{
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    padding: .6rem 0;
    display: flex;
    border: 1px solid transparent;
    transition: .2s ease-in-out;
    width: 170px;
    justify-content: center;
}
.link-rate-header-dashboard:hover{
    border: 1px solid #7b1cc383;
    text-decoration: none;
    transition: .2s ease-in-out;
    
}
.rate-header-dashboard{
    color: #c501ff !important;
    font-size: 10px !important;
    margin-right: 8px;
}
.rate-header-dashboard>.rs-rate-character {
    margin: 2px;
}
.icon-header-dashboard{
    color: #c501ff;
    width: 18px;
    height: 16px;
    margin-left: 10px;
}