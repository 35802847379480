.user-info-label{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.user-info-input{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.7);
    border-top: 0;
    border-left: 0;
    height: 35px;
    border-radius: 0;
    border-right: 0;
    padding-right: 3rem;
    padding-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-group-user-info{
    margin-bottom: 35px;
}
.user-info-input:focus{
    border-bottom: 1px solid #c501ff !important;
}
.user-info-input-icon{
    width: 21px;
    color: #c501ff;
    position: absolute;
    right: 10px;
    top: 4px;
}

.btn-user-info-form{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #c501ff;
    padding: 15px;
    border-radius: 25px;
    border: 1px solid #c501ff;
    width: 100%;
    transition: .2s ease-in-out;
    margin-bottom: 30px;
}
.btn-user-info-form:hover{
    background-color: #FFFFFF;
    transition: .2s ease-in-out;
    color: rgba(0, 0, 0, 0.7);
}
.btn-user-info-form:focus{
    background-color: #c501ff;
    transition: .2s ease-in-out;
    color: #fff;
    border: 1px solid transparent;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.height-select-region-ville{
    height: 40px;
}