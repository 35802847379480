.component-error404{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.component-error404 h1{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 155px;
    color: #c501ff;
    line-height: 1;
}
.component-error404 p{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

