body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.adding-item-container {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.easypm-modal-title {
  color: #0c0b0b;
  font-size: 18px;
}
.easypm-modal-header {
  border-bottom: none !important;
  padding-left: 25px;
  padding-right: 25px;
}
.easypm-modal-body {
  padding-left: 25px;
  padding-right: 25px;
}

.crud-form-group label {
  color: #0c0b0b;
  font-size: 13px;
}
.crud-form-control {
  padding: 22px;
  border-radius: 10px;
}

.crud-form-action-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crud-form-action-btn-container button {
  width: 100%;
  padding: 15px;
}
.crud-submit-btn {
  background: #c501ff !important;
  border-color: #c501ff !important;
  border-radius: 10px;
}
.crud-submit-btn:hover {
  color: #c501ff !important;
  background: transparent !important;
}
.cancelleb-btn:hover {
  background: #ff0000 !important;
  border-color: #ff0000 !important;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
}
.cancelleb-btn {
  background: transparent;
  color: #515151;
  font-size: 17px;
  font-weight: 500;
}
.crud-row {
  padding-top: 10px;
  padding-bottom: 20px;
}
.crud-form-group {
  margin-bottom: 20px;
}
.nameFormatter {
  color: #000;
  font-weight: 500;
}
.nameFormatter:hover {
  text-decoration: none;
  color: #01223a !important;
}
.crud-form-interm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crud-form-group.crud-interm {
  width: 49%;
  position: relative;
}

.form-group.required label:after {
  content: "*";
  color: red;
}

.special-label {
    display: none;
}

.Choisir__control.css-1s2u09g-control {
  padding: 5px !important;
  border-radius: 10px !important;
}

.Choisir__value-container.css-319lph-ValueContainer {
    text-align: start;
}

.linkable {
  cursor: pointer;
}

.shop-name {
  font-size: 16px;
  font-weight: 600;
  color: #c501ff;
}