.text-condition-generale {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 209.9%;
    color: #000000;
}
.titre-condition-generale {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-size: 50px;
    line-height: 61px;
    color: #000000;
}

.cgu-text p,
.cgu-text ol,
.cgu-text ul,
.cgu-text pre,
.cgu-text blockquote,
.cgu-text h1,
.cgu-text h2,
.cgu-text h3,
.cgu-text h4,
.cgu-text h5,
.cgu-text h6 {
  color: #000000 !important;
  font-size: 15px !important;
}