.breadcrumb-theme{
    padding-top: 40px;
    background: #FFFFFF;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    margin-top: 2px;
}
.logout-link-account{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #7b1cc38a;
    transition: .2s ease-in-out;
    background: transparent;
}
.logout-link-account:hover{
    color: #c501ff;
    transition: .2s ease-in-out;
    text-decoration: none;
}

.nav-tabs .nav-link-tabs-account.active, .nav-tabs .nav-item.show .nav-link-tabs-account {
    background-color: transparent;
    border-radius: 0;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    border-bottom: 3px solid #c501ff;
    transition: .2s ease-in-out;
    border-top: 1px solid transparent;
}
.nav-tabs .nav-link-tabs-account {
    border-color: transparent #C4C4C4 transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    width: 25%;
    transition: .2s ease-in-out;
    border-top: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent;
    transition: .2s ease-in-out;
    font-weight: 600;
    color: #000000;
    border-bottom: 3px solid #c501ff;
    /* border: 1px solid #c501ff; */
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
}
.content-card-account-infos{
    background-color: #ffffff;
    padding: 3rem;
}
.icon-nav-link-tabs-account{
    width: 17px;
    color: #c501ff;
    margin-right: 10px;
}

.content-card-account{
    background-color: #ffffff;
    padding: 2rem 2rem 100px;
}

.devenir-vendeur-compte-link{
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
}
.access-to-shop-link{
    background: #FFFFFF;
    border: 1px solid #c501ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 35px;
    padding: .5rem 2rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #000000;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
}
.access-to-shop-link:hover{
    text-decoration: none;
    transition: .2s ease-in-out;
    color: #c501ff;
    border: 1px solid transparent;
}
.icon-access-to-shop-link{
    width: 16px;
    height: 16px;
    margin-right: 10px;
    color: #c501ff;
}