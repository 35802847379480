.commande-item{
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    padding: 10px 2rem 30px;
    margin-bottom: 25px;
}

.text-commande-item{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    color: rgba(17, 17, 17, 0.7);
}
.img-thumbs-commande-item{
    width: 100%;
}
.qty-commande-item {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.8);
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
    height: 35px;
    padding-top: 7px;
    border-radius: 0;
    border-right: 0;
    padding-left: 10px;
    padding-right: 10px;
}
.qty-commande-item:focus{
    border-bottom: 1px solid #c501ff;
}
.icon-commande-favoris{
    width: 20px;
    color: #797A6F;
}
.icon-commande-delete{
    width: 20px;
    color: #F42D2D;
}
.btn-icon-action-commande{
    padding: 0;
    margin-right: 15px;
    border: 0;
    background: transparent;
}
.resume-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    color: #111111;
    position: relative;

}
.value-resume-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    color: #111111;
    position: absolute;
    right: 0;
}

img.image-commande-empty {
    width: 270px; 
    margin: 0 auto 75px;
    background-color: transparent;
}