.amount-container {
    position: absolute;
    top: -3px;
    right: 20px;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    background: #c501ff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.total-amount {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}