.rating-user-evaluation {
    color: #c501ff !important;
    width: 100%;
    font-size: 12px !important;
}

.fullname-user-evaluation {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.commentaire-user-evaluation {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.product-quantity-message {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #c501ff;
}

.content-sizing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.border-sizing {
    height: 35px;
    border-radius: 50px;
    border: 1px solid #c501ff !important;
    padding-left: 0.2rem;
}