.mandatory-login-btn{
    margin-bottom: 70px;

}
.mandatory-login-btn.btn-form-dark:hover{
    color: #c501ff;
    background-color: #fff;
    border: 1px solid transparent;
    text-decoration: none;
    transition: .2s ease-in-out;
}