.card-categorie-accueil{
    background-color: #ffffff;
    padding: 1rem 0 1rem 2rem;
    margin-bottom: 10px;
    position: relative;
    height: 100%;
}
.ul-categories{
    list-style: none;
    padding: 0;
}

.categories-link{
    display: flex;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #111111;
    transition: .3s ease-in-out;
    align-items: center;
    padding: 5px 0;
}
.categories-link:hover{
    color: #c501ff;
    text-decoration: none;
    transition: .3s ease-in-out;
}
.icon-categorie-link{
    margin-left: auto;
    width: 15px;
    margin-right: 1rem;
}

.content-categorie-link{
    display: none;
}
.categories-link:hover + .content-categorie-link{
    background-color: #f34fff;
    padding: 2rem;
    display: block;
}

.dropdown-categorie-list {
    position: static;
    display: inline-block;
    width: 100%;
}

.dropdown-content-categorie-list {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 480px;
    box-shadow: 6px 4px 8px 0px rgba(0,0,0,0.2);
    z-index: 9999;
    top: 0;
    left: 100%;
    padding: 40px 60px;
}

.link-dropdown-categorie-list {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.8);
    transition: .2s ease-in-out;
    margin-bottom: 10px;
    display: flex;
}
.link-dropdown-categorie-list:hover {
    color: #c501ff;
    text-decoration: none;
    transition: .2s ease-in-out;
}


.dropdown-categorie-list:hover .dropdown-content-categorie-list {display: block;}

.h-link-dropdown-categorie-list{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.8);
    transition: .2s ease-in-out;
    margin-bottom: 10px;
    display: flex;
}
.h-link-dropdown-categorie-list:hover{
    color: #c501ff;
    text-decoration: none;
    transition: .2s ease-in-out;
}