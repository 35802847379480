.image-product-upload{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 100%;
    height: 250px;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.input-custom-product-file{

}
.label-custom-product-file{
    background: #F4F4F4;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    height: 40px;
    padding-left: 3rem;
    padding-block: .7rem;
}
.custom-file-input:lang(en)~.label-custom-product-file::after {
    content: "\f03e";
    font-family: FontAwesome;
    left: 5px;
    background: transparent;
    height: 40px;
    display: flex;
    align-items: center;
    border: transparent;
}
.btn-remove-image-product-upload{
    color: #f42d2daf;
    position: absolute;
    top: 10px;
    right: 20px;
    transition: .2s ease-in-out;
    padding: 0;
    background: transparent;
    cursor: pointer;
}
.btn-remove-image-product-upload:hover{
    color: #f42d2d;
    transition: .2s ease-in-out;
}
.icon-remove-image-product-upload{
    width: 18px;
}
.check-picker-custom-marketplace>.rs-picker-toggle {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: 0;
    padding-left: 0;
    transition: .2s ease-in-out;
    background: transparent !important;
    border-bottom: 1px solid #ced4da;
}
.check-picker-custom-marketplace>.rs-picker-toggle:hover, .check-picker-custom-marketplace>.rs-picker-toggle:focus {
    border-bottom: 1px solid #c501ff !important;
}
.rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0 0 27px -3px rgb(0 0 0 / 20%), 0 -1px 5px 0px rgb(0 0 0 / 24%);
    overflow: hidden;
    transition: none;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.rs-check-item-focus, .rs-check-item:not(.rs-checkbox-disabled):hover {
    color: #fff;
    background-color: #7b1cc3b7;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:after {
    border-right: solid #fff;
    border-top: solid transparent;
    border-left: solid transparent;
    border-bottom: solid #fff;
    width: 6px;
    height: 9px;
    margin-top: 2px;
    margin-left: 5px;
    opacity: 1;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    display: block;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    color: #5A5A5A;
    background: transparent;
    background-image: none;
    border: 1px solid #3c3f4334;
    transition: border-color .3s ease-in-out;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    min-width: 200px;
    padding: 7px 36px 7px 11px;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #7b1cc3;
}
.rs-picker-value-count {
    margin: 0 4px;
    background: #7b1cc3;
    color: #fff;
    border-radius: 10px;
    padding: 0 8px;
    line-height: 20px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
    border-color: #c501ff;
    outline: 0;
}
.btn-start-recorder{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    background-color: #c501ff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    transition: .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-start-recorder:hover{
    background-color: #fff;
    color: #c501ff;
    transition: .2s ease-in-out;
}
.btn-start-recorder>svg{
    width: 20px;
    height: 20px;
}
.btn-finish-recorder{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    background-color: #CD1010;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    transition: .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-finish-recorder:hover{
    color: #fff;
    background-color: #CD1010;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    transition: .2s ease-in-out;
}
.btn-finish-recorder>svg{
    width: 20px;
    height: 20px;
}