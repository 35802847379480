.logo-header{
    width: 100px;
}
.nav-header-custom{
    padding: 20px;
}
.navbar-toggler {
    border: 0px solid transparent;
}
.navbar-light .navbar-toggler-icon {
    background-image: url(../../img/icons/icon-menu.svg);
}
.link-header-item{
    text-align: center;
    border-right: 1px solid #C4C4C4;
    text-decoration: none !important;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.icon-item-header{
    color: #c501ff;
    width: 22px;
    margin-bottom: 5px;
}
.text-item-header{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D2929;
    transition: .3s ease-in;
}
.text-item-header:hover{
    transition: .3s ease;
    color: #c501ff;
}




.icon-btn-search{
    color: #ffffff;
}
.cart-link-header-mobile{
    color: #c501ff;
    width: 27px;
}
.cart-mobile-header{
    padding: 5px 12px;
}
.icon-navbar-mobile{
    color: #c501ff;
    width: 15px;
    margin-right: 5px;
}
.link-navbar-mobile{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    color: #2D2929;
    transition: .4s ease-in;
}
.link-navbar-mobile:hover{
    color: #c501ff;
    transition: .4s ease-in;
    text-decoration: none;
}


/* DropDown categories header  */

.dropdown-categorie-header-desktop {
    position: static;
}

.btn-dropdown-categorie-header{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #322D2D;
    opacity: 0.75;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    margin-right: 40px;
    margin-left: 45px;
}
.btn-dropdown-categorie-header:hover{
    color: #c501ff;
    transition: .2s ease-in-out;
}
.btn-dropdown-categorie-header:focus{
    outline: none;
    color: #c501ff;
    transition: .2s ease-in-out;
}
.content-dropdown-categorie-header{
    top: 108px;
    transition: .2s ease-in-out;
    left: auto;
    padding: 0;
    min-width: 350px;
    border: 0;
    border-radius: 0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
    transition: .2s ease-in-out;
}
.icon-btn-dropdown-categorie-header{
    width: 18px;
    margin-right: 10px;
}
.link-content-dropdown-categorie-header{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #545057;
    transition: .2s ease-in-out;
}
.link-content-dropdown-categorie-header:hover{
    text-decoration: none;
    color: #c501ff;
    transition: .2s ease-in-out;
}

.add-vendeur-btn {
    border: 2px solid #ffeb1f;
    margin-right: 1rem !important;
    border-right: 2px solid #ffeb1f!important;
    background: #c501ff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 30%);
    
}

.add-vendeur-btn .text-item-header {
    color: #F2CB05 !important;
}

.add-vendeur-btn:hover {
    background: #ffffff
}

.add-vendeur-btn:hover .text-item-header, .add-vendeur-btn:hover .text-white {
    color: #c501ff !important;
}

 .add-vendeur-btn:hover .text-white {
    color: #2d2929 !important;
}