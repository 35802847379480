.form-left{
    background: #c501ff;
    border-radius: 10px 0px 0px 10px;
    padding: 3rem;
}
.form-right{
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 0px 10px 10px 0px;
    padding: 3rem 5rem;
}
.login-with-button{
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    display: block;
    margin-left: 30px;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}
.login-with-button>div{
    margin-right: 0 !important;
    border-radius: 50% !important;
    background: transparent !important;
}
.login-with-button>span{
    display: none;
}
.icon-login-with-button{
    width: 50%;
}
.icon-login-with-button-fb{
    width: 50px;
    position: absolute;
    border-radius: 50%;
}
.form-control-custom-form{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #5A5A5A;
    padding-left: 0;
    border-bottom: 1px solid #E9E9E9 !important;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
} 
.react-tel-input .form-control-custom-form {
    border: none !important;
    border-bottom: 1px solid #E9E9E9 !important;
}

.phone-input .flag-dropdown {
    background-color: transparent !important;
    border: 1px solid transparent !important;
}
.form-control-custom-form:focus{
    border-bottom: 1px solid #c501ff;
}
.form-group-custom-form{
    margin-bottom: 2rem;
}
.btn-form-dark{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #c501ff;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    border: 1px solid #c501ff;
    width: 100%;
    transition: .2s ease-in-out;
    margin-bottom: 30px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .btn-form-dark:hover{
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
} */

.custom-control-label-form-signup{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #5A5A5A;
}
.custom-checkbox .custom-control-label-form-signup::before {
    border-radius: 0rem;
    border: 2px solid #c501ff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label-form-signup::after {
    background-color: #c501ff;
}
.link-form-signup-login{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    transition: .2s ease-in-out;
}
.link-form-signup-login:hover{
    color: #c501ff;
    text-decoration: none;
    transition: .2s ease-in-out;
}
.btn-form-light{
    border: 1px solid #c501ff;
    box-sizing: border-box;
    border-radius: 25px;
    display: flex;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.15em;
    color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    padding: 15px;
    transition: .2s ease-in-out;
}
.btn-form-light:hover{
    background-color: #c501ff;
    text-decoration: none;
    transition: .2s ease-in-out;
    color: #ffffff;
}
.kep-login-facebook {
    padding: 0 !important;
    width: 50px;
    height: 50px;
    margin-left: 25px;
    border-radius: 50% !important;
    font-family: 'Roboto', sans-serif !important;
    text-transform: lowercase !important;
    font-size: 24px !important;
}