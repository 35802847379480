.link-terms-register{
    font-weight: 600;
    transition: .2s ease-in-out;
    color: rgba(0,0,0,.5);
}
.link-terms-register:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    color: #c501ff;

}