.card-dashboard-content-table{
    background: #FFFFFF;
    border-radius: 10px;
}
.head-card-dashboard-content-table{
    padding: 1.5rem 1.5rem 3rem;
}
.content-card-dashboard-content-table{
    padding-bottom: 3rem;
}
.nav-horizontal-commandes{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
    border-bottom: 2px solid transparent !important;
    transition: .2s ease-in-out;
    padding-left: 0;
    margin-right: 2%;
    position: relative;
    background: inherit;
    /* width: 11%; */
}

.nav-tabs .nav-item.show .nav-horizontal-commandes, .nav-tabs .nav-horizontal-commandes.active {
    color: #c501ff;
    background-color: #fff;
    border-color: transparent;
    border-bottom: 2px solid #c501ff !important;
    font-weight: 600;
    transition: .2s ease-in-out;
}

.nav-tabs .nav-horizontal-commandes:hover, .nav-tabs .nav-horizontal-commandes:focus {
    border-color: transparent;
    transition: .2s ease-in-out;
    font-weight: 600;
    color: #c501ff;
    border-bottom: 2px solid #c501ff !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: .2s ease-in-out;
}
.nav-tabs-horizontal-commandes{
    border-bottom: 1px solid transparent !important;
}