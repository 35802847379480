.link-previous-ariane{
    position: absolute;
    width: 16px;
    color: #000000;
    top: 2px;
}
.link-previous-ariane:hover{
    color: #c501ff;
}
.h-previous-ariane{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-left: 45px;
    color: rgba(0, 0, 0, 0.75);
}
.value-resume-item-ttc{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    color: #111111;
    position: absolute;
    right: 0;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 2px 15px;
}
.title-proccess-checkout-card{
    font-family: 'Advent Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 45px;
}
.step-item-process-card{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #c501ff;
    background-color: #c501ff;
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
}
.step-item-process-card-reverse{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #c501ff;
    background-color: #ffffff;
    color: #c501ff;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
}
.client-name-proccess-checkout{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
}
.client-info-proccess-checkout{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.input-item-step-checkout{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    color: rgba(0, 0, 0, 0.7);
}

.custom-control-input:checked~.input-item-step-checkout::before {
    color: #fff;
    border-color: #c501ff;
    background-color: #c501ff;
}

.custom-radio .input-item-step-checkout::before {
    border-radius: 50%;
    border: 2px solid #c501ff;
}
.orange-money-icon-item-step-checkout{
    margin-bottom: 10px;
    width: 40px;
    margin-top: -10px;
}
.btn-edit-step-item-process-card{
    padding: 0;
    border: 0;
    background: #fff;
    color: #7b1cc377;
    position: absolute;
    right: 15px;
    top: 9px;
    transition: .2s ease-in-out;
}
.btn-edit-step-item-process-card:hover{
    color: #c501ff;
    transition: .2s ease-in-out;
}
.icon-edit-step-item-process-card{
    width: 20px;
}
.btn-edit-step-item-process-card:focus{
    outline: none;
}