.btn-evaluate-product-modal{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    display: inline-flex;
    align-items: center;
    background: #FFFFFF;
    border: 0.5px solid #c501ff;
    box-sizing: border-box;
    border-radius: 55px;
    padding: .8rem 2rem;
    transition: .2s ease-in-out;
}
.btn-evaluate-product-modal:hover{
    text-decoration: none;
    transition: .2s ease-in-out;
    color: #c501ff;
    border: 0.5px solid transparent;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.icon-btn-evaluate-product-modal{
    color: #c501ff;
    margin-right: 15px;
    width: 20px !important;
    height: 20px !important;
}
.rate-evaluate-product-modal{
    color: #c501ff;
}
.placeholder-evaluate-product-modal{
    border: 0.5px solid #E4E3E3;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #4E4A4A;
    padding: 20px 15px;
}
.placeholder-evaluate-product-modal:focus{
    outline: none;
}
.cancel-btn-evaluate-product-modal{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    font-variant: small-caps;
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid #c501ff;
    border-radius: 55px;
    text-transform: uppercase;
    padding: .5rem 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #FFFFFF;
    transition: .2s ease-in-out;
}
.cancel-btn-evaluate-product-modal:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    background-color: #c501ff;
    color: #FFFFFF;
}
.submit-btn-evaluate-product-modal{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    font-variant: small-caps;
    color: #ffffff;
    border: 1px solid #c501ff;
    border-radius: 55px;
    text-transform: uppercase;
    padding: .5rem 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #c501ff;
    transition: .2s ease-in-out;    
}
.submit-btn-evaluate-product-modal:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    background-color: #ffffff;
    color: #c501ff;    
}