.content-item-card{
    background-color: #ffffff;
}
.content-item-card-head{
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
    height: 50px;
}
.content-item-card-body{
    background-color: #ffffff;
    padding: 2rem 1rem;
}
.dropbtn-sort {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #827E7E;
    background-color: transparent;
    border: transparent;
}

.dropdown-sort {
    position: relative;
    display: inline-block;
    line-height: 0;
    height: 14px;
}

.dropdown-content-sort {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: .3s ease-in-out;
    padding: 1rem 2rem;
    right: 0;
}

.dropdown-content-sort a {
    color: black;
    padding: 15px 0px;
    text-decoration: none;
    display: block;
    transition: .3s ease-in-out;
}

.dropdown-sort:hover .dropdown-content {display: block;}

.icon-chevron-dropdown{
    width: 12px;
    margin-left: 7px;
    height: 12px;
}

.content-title-section-left {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.input-range__track {
    height: 0.25rem !important;
}

.input-range__slider {
    border: 1px solid #c501ff !important;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 12px !important;
    margin-left: -0.5rem;
    margin-top: -0.48rem !important;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 12px !important;
}

.input-range__slider,
.input-range__track--active {
    background: #c501ff !important;
}

.btn-price-selected,
.price-container {
    border: 0.5px solid #c501ff;
    border-radius: 10px;
}

.container-price {
    gap: 0.5rem !important;
}

.price-container {
    border: 0.5px solid #c501ff;
    border-radius: 10px;
    width: 95px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-price-selected {
    background: #c501ff;
    border: 0.5px solid #c501ff;
    border-radius: 10px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

.btn-price-selected:hover {
    border: 0.5px solid #c501ff;
    color: #c501ff;
    background: #fff;
}

.product-card-item {
    /* padding: 15px 0px 0px 0px; */
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    margin-bottom: 25px;
}

.product-card-item-half-top {
    /* padding-left: 25px;
    padding-right: 25px; */
    border-bottom: 1px solid #EEEEEE;
}

.content-img-product-card-item {
    /* height: 240px; */
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.content-img-product-card-item.items4 {
    /* height: 300px; */
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.img-product-card-item {
    width: 100% !important;
    min-height: 280px !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.name-product-card-item {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    /* line-height: 14px; */
    text-align: center;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    padding: 0 20px;
}

.name-product-card-item::first-letter {
    text-transform: capitalize;
}

.price-product-card-item {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    color: #c501ff;
    margin-bottom: 5px;
    text-align: center;
}

.rating {
    font-size: 14px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
}

.rating a {
    float: right;
    color: #aaa;
    text-decoration: none;
    -webkit-transition: color .4s;
    -moz-transition: color .4s;
    -o-transition: color .4s;
    transition: color .4s;
}

.rating a:hover,
.rating a:hover~a,
.rating a:focus,
.rating a:focus~a {
    color: #c501ff;
    cursor: pointer;
    text-decoration: none;
}

.rating2 {
    direction: rtl;
}

.rating2 a {
    float: none
}

.btn-product-card-item-add-card {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.7);
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border: 0;
    transition: .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-product-card-item-add-card:hover {
    background-color: #7b1cc3e3;
    transition: .2s ease-in-out;
    color: #fff;
}

.icon-btn-product-card-item-add-card {
    width: 18px;
    margin-right: 15px;
}

.btn-add-favoris-product-card-item {
    position: absolute;
    top: 15px;
    right: 20px;
    padding: 0;
    border: 0;
    background: transparent;
    color: #7b1cc3a4;
    transition: .2s ease-in-out;
}

.btn-add-favoris-product-card-item:hover {
    transition: .2s ease-in-out;
    color: #7b1cc3;
}

.link-to-details-product {
    text-decoration: none !important;
    transition: transform .2s ease-in-out;
}

.link-to-details-product:hover {
    transform: scale(1.5);
}

.rating-custom-product-item {
    color: #c501ff !important;
    width: 100%;
    display: flex !important;
    justify-content: center;
    font-size: 10px !important;
    margin: 10px 0 !important;
}

.rating-custom-product-item>li {
    margin: 3px !important;
}

.rating-custom-medium {
    color: #c501ff !important;
    width: 100%;
    display: flex !important;
    font-size: 18px !important;
    margin: 10px 0 !important;
}

.rating-custom-medium>li {
    margin: 5px 10px 5px 0 !important;
}

.heart {
    width: 100px;
    height: 100px;
    background: url(https://cssanimation.rocks/images/posts/steps/heart.png) no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
    position: absolute;
    left: -20%;
    top: -20%;
}

.heart.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
}