.edit-icon-modal{
    width: 20px;
}
.edit-btn-modal{
    width: 50px;
    height: 50px;
    background: #ffffff !important;
    border-radius: 50%;
    color: #c501ff !important;
    border: 1px solid #ffffff;
    transition: .2s ease-in-out;
    opacity: .8;
}
.edit-btn-modal:hover{
    opacity: 1;
    transition: .2s ease-in-out;
}