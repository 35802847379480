.amount {
    color: #343a40;
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.amount-btn {
    width: 18px;
    height: 18px;
}

.amount-btn.left {
    background: transparent;
}

.amount-btn.right {
    background: transparent;
    margin-right: .5rem;
}

.component-cart-item::selection {
    background-color: red !important;
}

.icon-amount-btn {
    height: 17px;
    width: 17px;
    margin-top: -10px;
    color: #c501ff;
}

.content-qty-cart-item {
    display: flex;
    justify-content: center;
    border: 2px solid #c501ff;
    padding: 0 4px;
    width: 90px;
    gap: 1rem;
}