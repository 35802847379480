td:focus {
    outline: none;
}

.head-table-custom>.react-bs-table>.react-bs-container-header>.table {
    margin-bottom: 0;
}

.head-table-custom>.react-bs-table>.react-bs-container-header {
    border-top: 1px solid #ddd;
}

.head-table-custom>.react-bs-table-bordered {
    border: 1px solid transparent;
}

.th-table-custom {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #c501ff;
    padding: 1.5rem !important;
}

.table-container {
    font-family: 'Montserrat', sans-serif;
}

.pagination {
    padding-right: 1rem;
    list-style: none;
    justify-content: flex-end;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #c501ff;
    border-color: #c501ff;
}

.btn-action-table-grey,
.btn-action-table-grey:active,
.btn-action-table-grey:focus {
    margin: 0 10px;
    padding: 0;
    background-color: transparent;
    color: #B5B5B5;
    transition: .2s ease-in-out;
}

.btn-action-table-red {
    margin: 0 10px;
    padding: 0;
    background-color: transparent;
    color: #f25757;
    transition: .2s ease-in-out;
}

.btn-action-table-grey.check:hover,
.btn-action-table-grey.check:active {
    color: #1CC39B;
    transition: .2s ease-in-out;
}

.btn-action-table-grey.edit:hover,
.btn-action-table-grey.edit:active {
    color: #c501ff;
    transition: .2s ease-in-out;
}

.btn-action-table-grey.clear:hover,
.btn-action-table-grey.clear:active {
    color: #E31D59;
    transition: .2s ease-in-out;
}

.icon-action-table-grey {
    width: 18px !important;
}

.btn-icon-table-theme {
    color: #7b1cc3b2;
    background-color: transparent;
    transition: .2s ease-in-out;
}

.btn-icon-table-theme:hover {
    color: #c501ff;
    transform: scale(1, 1);
    transition: .2s ease-in-out;
}

.icon-order {
    width: 20px;
}