.featured,
.featured:focus,
.featured:active {
    color: #c501ff !important;
}

.featured:focus {
    /* color: #B5B5B5 !important; */
}

.featured-reverse {
    color: #B5B5B5 !important;
}

.featured-reverse:focus {
    /* color: #c501ff !important; */
}