.carousel-multi-item.v-2.product-carousel .carousel-inner .carousel-item.active,
.carousel-multi-item.v-2.product-carousel .carousel-item-next,
.carousel-multi-item.v-2.product-carousel .carousel-item-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
.carousel-multi-item.v-2.product-carousel .carousel-item-right.active,
.carousel-multi-item.v-2.product-carousel .carousel-item-next {
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%); }
.carousel-multi-item.v-2.product-carousel .carousel-item-left.active,
.carousel-multi-item.v-2.product-carousel .carousel-item-prev {
  -webkit-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  transform: translateX(-20%); }
.carousel-multi-item.v-2.product-carousel .carousel-item-right,
.carousel-multi-item.v-2.product-carousel .carousel-item-left {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0); }
.carousel-multi-item.v-2.product-carousel *, .carousel-multi-item.v-2.product-carousel ::after, .carousel-multi-item.v-2.product-carousel ::before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
.fuchsia-rose-text {
  color: #db0075;
}
.aqua-sky-text {
  color: #5cc6c3;
}
.mimosa-text {
  color: #F0C05A;
}
.list-inline-item .fas, .list-inline-item .far {
  font-size: .8rem;
}
.chili-pepper-text {
  color: #9B1B30;
}
.carousel-multi-item .controls-top .btn-floating {
  background: #F8CDCD;
}
.carousel-multi-item .carousel-indicators li {
  height: .75rem;
  width: .75rem;
  max-width: .75rem;
  background-color: #5cc6c3;
}
.carousel-multi-item .carousel-indicators .active {
  height: 1rem;
  width: 1rem;
  max-width: 1rem;
  background-color: #5cc6c3;
}
.carousel-multi-item .carousel-indicators {
  margin-bottom: -1rem;
}