.modal-details-message-new {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  background: #c501ff;
  border-radius: 100px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0.5rem;
}
.modal-details-message-new:hover {
  color: #c501ff;
  border: 1px solid #c501ff;
  background: #fff;
}

.modal-details-message-en_cours {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  background: #ffc107;
  border-radius: 100px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0.5rem;
}
.modal-details-message-en_cours:hover {
  color: #ffc107;
  border: 1px solid #ffc107;
  background: #fff;
}

.modal-details-message-traité {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  background: #14b253;
  border-radius: 100px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0.5rem;
}
.modal-details-message-traité:hover {
  color: #14b253;
  border: 1px solid #14b253;
  background: #fff;
}

.admin-message-card-frequence {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.admin-message-card-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.7);
}

.admin-message-taches-title-container,
.admin-message-card-description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-message-card {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding-bottom: 40px;
}

.admin-message-card-btn-edit,
.admin-message-card-btn-trash {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #003da5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-message-card-btn-actions {
  display: flex;
  gap: 10px;
}

.admin-message-card-btn-icons {
  color: #c8c8c8;
  width: 20px;
  height: 20px;
}

.visually-hidden {
  display: none !important;
}

.diekkes-modal-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  /* text-align: center; */
  color: #000000;
  margin: 0;
}

.admin-form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1b1b1b;
  margin-bottom: 0.3rem;
}

.admin-btn-typo {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

.admin-btn-add {
  background: #c501ff;
  border-radius: 5px;
  border: 1px solid #c501ff;
  padding: 0.3rem 3rem;
  color: #ffffff;
}
.admin-btn-add:hover {
  background: #ffffff;
  color: #c501ff;
}
.admin-btn-cancel {
  color: #000000;
  background-color: transparent;
  border: none;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.text-response-card p {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
}

.text-response-card {
  color: #000;
}

.ql-editor {
  min-height: 200px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  color: #000000;
  font-size: 15px;
}

.form-label-custom {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #1B1B1B;
}

.admin-form-control {
	border-radius: 5px;
}

.btn-theme-profil {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #FFFFFF;
    background: #c501ff;
    border-radius: 5px;
    padding: .5rem;
}

.btn-close-theme {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #000000;
    background: transparent;
}