.steps-content{
    background-color: #fff;
    padding: 4rem;
}
/* .step-horizontal{
    margin-left: 50px;
} */
.step-horizontal>.rs-steps-item-content>.rs-steps-item-title::after{
    border-top-width: 4px;
    top: 11px;
    border-color: #c501ff;
    /* margin-left: -10px; */
}
.step-horizontal>.rs-steps-item-icon-wrapper{
    background: #FFFFFF;
    border: 1px solid #c501ff;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    border-radius: 50%;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: #c501ff;
    background: #c501ff;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #fff !important;
}

.step-horizontal>.rs-steps-item-icon-wrapper>.rs-steps-item-icon-finish{
    position: relative;
    text-indent: -9999px;
    background: #c501ff;
    border-radius: 50%;
    color: #ffffff;
    left: -1px;
    top: -1px;
    width: 30px;
    height: 30px;
}
.step-horizontal>.rs-steps-item-icon-wrapper>.rs-steps-item-icon-finish::before{
    color: #ffffff !important;
}
.step-horizontal>.rs-steps-item-icon-wrapper>.rs-steps-item-icon-wait{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
}

.step-horizontal>.rs-steps-item-content>.rs-steps-item-description{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.consigne-title-steps{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 30px;
}
.label-steps-inscription-vendeur{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
}
.input-steps-inscription-vendeur{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: 0;
    padding-left: 0;
    transition: .2s ease-in-out;
}
.input-steps-inscription-vendeur:focus{
    border-bottom: 1px solid #c501ff;
    transition: .2s ease-in-out;
}
.checkbox-steps-inscription-vendeur{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #5A5A5A;
}
.checkbox-steps-inscription-vendeur::before{
    width: 13px;
    height: 13px;
    border-radius: 0 !important;
    border: 1px solid #c501ff;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    margin-top: -2px;

}
.checkbox-steps-inscription-vendeur::after{
    width: 13px;
    height: 13px;
    margin-top: -2px;
}
.custom-control-input:checked~.checkbox-steps-inscription-vendeur::before {
    color: #fff;
    border-color: #c501ff;
    background-color: #c501ff;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.link-terms-steps-inscription-vendeur{
    font-weight: 600;
    color: #5A5A5A;
    transition: .2s ease-in-out;
}
.link-terms-steps-inscription-vendeur:hover{
    transition: .2s ease-in-out;
    color: #c501ff;
    text-decoration: none;
}

.select-steps-inscription-vendeur{
    appearance: none;
    background: url(../../img/icons/chevron-down-select.svg) 98% / 20px no-repeat transparent;
    font-family: 'Raleway',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
    border-top: none;
    border-left: none;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 30px;
    transition: .2s ease-in-out;
    border-bottom: 1px solid #ced4da;
}
.select-steps-inscription-vendeur:focus{
    border-bottom: 1px solid #c501ff;
    transition: .2s ease-in-out;
}
.select-steps-inscription-vendeur>option{
    padding: 15px !important;
    background-color:#fff;
}

.select-steps-inscription-vendeur>option:checked {
    background-color: #c501ff !important;
    color: white;
}
.select-steps-inscription-vendeur>option:hover {
    background-color: red !important;
    color: white;
}



#accrodionResumeSteps {
    margin: 50px 0;
}

#accrodionResumeSteps #faq .card {
    margin-bottom: 30px;
    border: 0;
}

#accrodionResumeSteps #faq .card .card-header {
    border: 0;
    background-color: transparent;
    padding: 0;
}
  
.btn-header-link {
    display: inline-block;
    text-align: left;
    text-decoration: none !important;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7) !important;
    padding-left: 1.5rem;
}
  
#accrodionResumeSteps #faq .card .card-header .btn-header-link:after {
    content: "\f0dd";
    font-family: 'FontAwesome';
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 0;
}

.btn-header-link.collapsed {
    display: inline-block;
}

#accrodionResumeSteps #faq .card .card-header .btn-header-link.collapsed:after {
    content: "\f0da";
    top: 4px;
}

#accrodionResumeSteps #faq .card .collapse {
border: 0;
}

#accrodionResumeSteps #faq .card .collapse.show {
    /* background: #FFE472;
    line-height: 30px;
    color: #222; */
}
.btn-theme.creating-shop{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #c501ff;
    padding: 13px 0;
    border-radius: 25px;
    border: 1px solid #c501ff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .8;
}