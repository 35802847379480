.s003 form {
    width: 100%;
    max-width: 790px;
    margin-bottom: 0;
}

.s003 form .inner-form {
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 55px;
}

.s003 form .inner-form .input-field {
    height: 50px;
}

.s003 form .inner-form .input-field input {
    height: 100%;
    background: #f7f7f7;
    border: 0;
    display: block;
    width: 100%;
    border-radius: 50px;
    padding: 10px 60px 10px 30px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0px;
    color: rgba(50, 45, 45, 0.5);
    opacity: 0.75;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.s003 form .inner-form .input-field input.placeholder {
    color: #888;
    font-size: 16px;
}

.s003 form .inner-form .input-field input:-moz-placeholder {
    color: #888;
    font-size: 16px;
}

.s003 form .inner-form .input-field input::-webkit-input-placeholder {
    color: #888;
    font-size: 16px;
}

.s003 form .inner-form .input-field input:hover, .s003 form .inner-form .input-field input:focus {
    box-shadow: none;
    outline: 0;
    border-color: #fff;
}

.s003 form .inner-form .input-field.first-wrap {
    width: 200px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.s003 form .inner-form .input-field.first-wrap .choices__inner {
    background: transparent;
    border-radius: 0;
    border: 0;
    height: 100%;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: 10px 30px;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__list.choices__list--single {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -ms-flex-align: center;
        align-items: center;
    height: 100%;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__item.choices__item--selectable.choices__placeholder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    height: 100%;
    opacity: 1;
    color: #888;
}

.s003 form .inner-form .input-field.first-wrap .choices__inner .choices__list--single .choices__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    height: 100%;
    color: #555;
}

.s003 form .inner-form .input-field.first-wrap .choices[data-type*="select-one"]:after {
    right: 30px;
    border-color: #e5e5e5 transparent transparent transparent;
}

.s003 form .inner-form .input-field.first-wrap .choices__list.choices__list--dropdown {
    border: 0;
    background: #fff;
    padding: 20px 30px;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.s003 form .inner-form .input-field.first-wrap .choices__list.choices__list--dropdown .choices__item--selectable {
    padding-right: 0;
}

.s003 form .inner-form .input-field.first-wrap .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background: #fff;
    color: #63c76a;
}

.s003 form .inner-form .input-field.first-wrap .choices__list--dropdown .choices__item {
    color: #555;
    min-height: 24px;
}

.s003 form .inner-form .input-field.second-wrap {
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.s003 form .inner-form .input-field.third-wrap {
    width: 74px;
}

.s003 form .inner-form .input-field.third-wrap .btn-search {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    color: #fff;
    border: 0;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
    background: #c501ff;
    transition: all .2s ease-out, color .2s ease-out;
}


.s003 form .inner-form .input-field.third-wrap .btn-search:hover {
    background: #5c1592;
}

.s003 form .inner-form .input-field.third-wrap .btn-search:focus {
    outline: 0;
    box-shadow: none;
}

@media screen and (max-width: 992px) {
    .s003 form .inner-form .input-field {
        height: 50px;
    }
}

@media screen and (max-width: 767px) {

    .s003 form .inner-form .input-field {
        margin-bottom: 20px;
        border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    }
    .s003 form .inner-form .input-field input {
        padding: 10px 15px;
    }
    .s003 form .inner-form .input-field.first-wrap {
        width: 100%;
        border-right: 0;
    }
    .s003 form .inner-form .input-field.first-wrap .choices__inner {
        padding: 10px 15px;
    }
    .s003 form .inner-form .input-field.first-wrap .choices[data-type*="select-one"]:after {
        right: 11.5px;
        border-color: #e5e5e5 transparent transparent transparent;
    }
    .s003 form .inner-form .input-field.second-wrap {
        width: 100%;
        margin-bottom: 0px;
    }
    .s003 form .inner-form .input-field.second-wrap input {
        border: 0px solid rgba(255, 255, 255, 0.3);
    }
    .s003 form .inner-form .input-field.third-wrap {
        margin-bottom: 0;
    }
}
    
.search-result-container {
    padding: 0px 45px;
    background: #fff;
    position: absolute;
    width: 450px;
    border: 1px solid #ececec;
}
input:focus+.search-result-container {
    padding: 1rem 2rem;
    background: #fff;
    position: absolute;
    width: 100%;
    border: 1px solid #7b1cc32a;
    display: block;
}

.search-result-item {
    padding: 5px 0;
    display: block;
    color: #000000ef ;
    font-family: 'Raleway', sans-serif;
    transition: .2s ease-in-out;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
}
.search-result-item:hover {
    transition: .2s ease-in-out;
    color: #c501ff;
    text-decoration: none;

}
.icon-input-search{
    position: absolute;
    top: 30%;
    right: 15px;
    color: #888;
}