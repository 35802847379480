.evaluation-content-details-page{
    background: #FFFFFF;
    padding: 1rem 4rem;
}
.titre-evaluation-content-details-page{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
}
.titre-evaluation-content-details-page::first-letter{
    text-transform: capitalize;
}
.pourcentage-evaluation-content-details-page{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: rgba(0, 0, 0, 0.75);
    margin-left: 20px;
}
.commentaire-content-details-page{
    background-color:  #ffffff;
}
.head-commentaire-content-details-page{
    padding: .8rem 4rem;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
    height: 50px;
}
.body-commentaire-content-details-page{
    padding: 1rem 4rem;
}
.item-content-commentaire-content-details-page{
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
    padding-bottom: 40px;
    padding-top: 25px;
}
.titre-product-commentaire-item{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.commentaire-product-commentaire-item{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 25px;
}
.auteur-product-commentaire-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
}
.rating-comment-product-head {
    color: #c501ff !important;
    font-size: 11px !important;
    margin-left: 20px;
}