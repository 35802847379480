.auth-page-content {
    background: #E5E5E5;
    padding-bottom: 150px;
}
.auth-page-col {
    margin-top: 50px;
}
.auth-form-row {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 50px 25px;
}
.auth-form-title {
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: rgba(24, 24, 25, 0.9);
    letter-spacing: -0.5px;
}
.form-group.auth-form-group label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(24, 24, 25, 0.9);
}
.auth-form-control {
    background: #E5E5E5;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
}
.auth-checkbox-container {
    display: flex;
    align-items: center;
}
.auth-checkbox-container label {
    margin-bottom: 0 !important;
    padding-left: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(24, 24, 25, 0.9);
}
input#session {
    width: 16px;
    height: 16px;
}
.auth-submit-btn {
    width: 100%;
    background: #c501ff;
    border-radius: 10px;
    border-color: #c501ff;
    padding: 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
.auth-submit-btn:hover {
    background: transparent !important;
    color: #c501ff !important;
    border: 1px solid #c501ff !important;
}
.auth-reset-password-btn {
    background: transparent;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    color: #c501ff;
    width: 100%;
}
.auth-reset-password-btn:hover {
    color: #000 !important;
}
.icon-password {
    position: absolute;
    top: 30%;
    left: 3%;
}
.icon-idenfiant {
    position: absolute;
    top: 30%;
    left: 3%;
}
.header-empty-login {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    padding: 20px 120px;
}
.connexion-page {
    overflow-x: hidden;
    height: 100vh;
    background: #fff;
}
.text-modifier-password {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #292929;
    opacity: 0.8;
}
.fixed-after-header-connexion {
    margin-top: 100%;
}