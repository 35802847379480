.ajouter-btn-modal{
    background: #ffffffd5;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    width: 200px;
    border: 1px solid transparent;
}
.ajouter-btn-modal:hover, .ajouter-btn-modal:focus{
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.7) !important;
}