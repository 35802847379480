.form-control-footer{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    border: 0;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.5);
    background: #292929;
}
.form-control-footer:focus{
    background: #292929 !important;
    color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 5px rgba(122, 28, 195, 1) !important;
}
.form-control-footer:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: red !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}
.btn-submit-form-footer{
    background: #c501ff;
    border-radius: 55px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.05em;
    color: #ffffff;
    border: 1px solid #c501ff;
    padding: .3rem 1.8rem;
    transition: .2s ease-in-out;
}
.btn-submit-form-footer:hover{
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    transition: .2s ease-in-out;
}